import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Menu, Drawer } from 'antd';
import { UnorderedListOutlined, MailOutlined, SettingOutlined, AppstoreOutlined } from '@ant-design/icons';
import '../../App.css';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem(<a className="no-underline" href='/'>Нүүр</a>, 'sub6', null),
  getItem('Бидний тухай', 'sub1', null, [
    getItem(<a className="no-underline" href='/pages/7'>Мэндчилгээ</a>, '1'),
    getItem(<a className="no-underline" href='/pages/8'>Танилцуулга</a>, '2'),
    getItem(<a className="no-underline" href='/pages/9'>Зорилго зорилт</a>, '3'),
    getItem(<a className="no-underline" href='/pages/10'>Үйл ажиллагааны чиг үүрэг</a>, '4'),
    getItem(<a className="no-underline" href='/pages/11'>Бүтэц зохион байгуулалт</a>, '5'),
    getItem(<a className="no-underline" href='/pages/12'>Бидний бахархал</a>, '6'),
  ]),
  getItem('Мэдээ мэдээлэл', 'sub2', null, [
    getItem(<a className="no-underline" href='/news/1'>Мэдээ мэдээлэл</a>, '7'),
    getItem(<a className="no-underline" href='/news/2'>Эрт илрүүлэг</a>, '8'),
    // getItem(<a className="no-underline" href='/news/3'>Статистик мэдээ</a>, '9'),
    // getItem(<a className="no-underline" href='/news/4'>Төсвийн мэдээ</a>, '10'),
    getItem(<a className="no-underline" href='/news/5'>Эмчийн зөвлөгөө</a>, '101'),
  ]),
  getItem('Хууль эрхзүй', 'sub3', null, [
    getItem(<a className="no-underline" href='/pages/17'>Нийтээр мөрдөх журам</a>, '11'),
    getItem(<a className="no-underline" href='/pages/18'>Эрүүл мэндийн тухай хууль тогтоомж</a>, '12'),
  ]),
  getItem('Албадууд', 'sub4', null, [
    getItem(<a className="no-underline" href='/pages/64'>Хүний нөөцийн алба</a>, '13'),
    getItem(<a className="no-underline" href='/pages/74'>Санхүүгийн алба</a>, '17'),
    getItem('Чанарын алба »', 'sub4-1', null, [
      getItem(<a className="no-underline" href='/pages/84'>Өргөдөл, гомдол</a>, '18'),
      getItem(<a className="no-underline" href='/pages/94'>Статистик мэдээлэл</a>, '22'),
      getItem(<a className="no-underline" href='/pages/104'>Ёс зүй</a>, '23'),
    ]),
  ]),
  getItem(<a className="no-underline" href='/pages/17'>Цахим үйлчилгээ</a>, 'sub5', null),
  getItem(<a className="no-underline" href='/pages/17'>Сургалт</a>, 'sub6', null),
  getItem('Ил тод байдал', 'sub7', null, [
    getItem(<a className="no-underline" href='/pages/17'>Хүний нөөц</a>, '37'),
    getItem(<a className="no-underline" href='/pages/17'>Худалдан авах</a>, '38'),
    getItem(<a className="no-underline" href='/pages/17'>Авилгатай тэмцэх</a>, '39'),
    getItem(<a className="no-underline" href='/pages/17'>Үйл ажиллагаа</a>, '40'),
  ]),
];
export default function Index() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClickMenu = (e) => {
    console.log('click ', e);
  };
  return (
    <div className='flex'>
      <div className={window.innerWidth > 640 ? 'w-full text-center text-2xl menuNone' : 'w-full text-center text-2xl'}>
        <UnorderedListOutlined onClick={showDrawer} style={{}} />
      </div>
        <img
         style={{height:50, position:"absolute", marginLeft:"-340px"}}
          className=""
          src="/assets/images/Logo_1.png"
          alt="Image One"
        />
      <Drawer title="" placement="right" onClose={onClose} open={open} className='ml-20'>
        <Menu
          onClick={onClickMenu}
          style={{
            width: 256,
          }}
          mode="inline"
          items={items}
        />
      </Drawer>
      <ul id="nav" className='max-sm:invisible sm:visible'>
        <li className='active'>
          <Link to="/">Нүүр</Link>
        </li>
        <li>
          <a href="javascript:void(0)" className="dropbtn">Бидний тухай</a>
          <ul>
            <Link to="/pages/7">Мэндчилгээ</Link>
            <Link to="/pages/8">Танилцуулга</Link>
            <Link to="/pages/9">Зорилго зорилт</Link>
            <Link to="/pages/10">Үйл ажиллагааны чиг үүрэг</Link>
            <Link to="/pages/11">Бүтэц зохион байгуулалт</Link>
            <Link to="/pages/12">Бидний бахархал</Link>
          </ul>
        </li>
        <li>
          <a href="javascript:void(0)" className="dropbtn">Мэдээ мэдээлэл</a>
          <ul>
            <Link to="/news/1">Мэдээ мэдээлэл</Link>
            <Link to="/news/2">Эрт илрүүлэг</Link>
            {/* <Link to="/news/3">Статистик мэдээ</Link>
            <Link to="/news/4">Төсвийн мэдээ</Link> */}
            <Link to="/news/5">Эмчийн зөвлөгөө</Link>
          </ul>
        </li>
        <li>
          <a href="javascript:void(0)" className="dropbtn">Хууль эрхзүй</a>
          <ul>
            <Link to="/pages/17">Нийтээр мөрдөх журам</Link>
            <Link to="/pages/18">Эрүүл мэндийн тухай хууль тогтоомж</Link>
          </ul>
        </li>
        <li className="dropdown sub">
          <a href="javascript:void(0)" className="dropbtn">Албадууд</a>
          <ul>
            <Link to="/pages/64">Хүний нөөцийн алба</Link>
            <Link to="/pages/74">Санхүүгийн алба</Link>
            <li style={{ width: '100%' }}><a href="#">Эрүүл мэндийн тусламж үйлчилгээний Чанар аюулгүй байдлын алба »</a>
              <ul>
                <li><Link to="/pages/104">Эмчилгээ оношилгоо сувилгааны удирдамж</Link></li>
                <li><Link to="/pages/134">Сэтгэл ханамжийн судалгааны үр дүн</Link></li>
                <li><Link to="/pages/144">Чанарын үзүүлэлт, үр дүн</Link></li>
                <li><Link to="/pages/154">Статистик мэдээлэл</Link></li>
                <li><Link to="/pages/164">Өргөдөл, гомдол</Link></li>
                <li><Link to="/pages/84">Эмнэлгийн тусламж үйлчилгээнд нэвтрүүлэх шинэ технологи</Link></li>
                {/* <li><Link to="/pages/174">Халдварын хяналт сэргийлэлтийн алба</Link></li> */}
                {/* <li><Link to="/pages/184">Хүний нөөц, сургалт хөгжлийн алба</Link></li> */}
                {/* <li><Link to="/pages/194">Статистик, эрүүл мэндийн даатгал мэдээлэл технологийн алба</Link></li> */}
                {/* <li><Link to="/pages/204">Сувилахуйн алба</Link></li> */}
                {/* <li><Link to="/pages/214">Эмзүйн алба</Link></li> */}
              </ul>
            </li>
            {/* <Link to="/pages/114">Сувилахын алба</Link> */}
            <Link to="/pages/174">Халдварын хяналт сэргийлэлтийн алба</Link>
            <Link to="/pages/184">Хүний нөөц, сургалт хөгжлийн алба</Link>
            <Link to="/pages/194">Статистик, эрүүл мэндийн даатгал мэдээлэл технологийн алба</Link>
            <Link to="/pages/204">Сувилахуйн алба</Link>
            <Link to="/pages/214">Эмзүйн алба</Link>
          </ul>
        </li>
        <li>
          <Link to="/pages/43">Цахим үйлчилгээ</Link>
        </li>
        <li>
          <Link to="/pages/44">Сургалт</Link>
        </li>
        <li>
          <a href="javascript:void(0)" className="dropbtn">ил тод байдал</a>
          <ul>
            {/* <Link to="/pages/45">Хүний нөөц</Link>
            <Link to="/pages/46">Худалдан авах</Link>
            <Link to="/pages/47">Авилгатай тэмцэх</Link>
            <Link to="/pages/48">Үйл ажиллагаа</Link> */}
            <li style={{ width: '100%' }}><a href="#">Төлөвлөгөө тайлан »</a>
              <ul>
                {/* <li style={{ width: '100%' }}><Link to="/pages/48">Төлөвлөгөө</Link></li> */}
                <li style={{ width: '100%' }}><Link to="/pages/47">Тайлан</Link></li>
              </ul>
            </li>
            <li style={{ width: '100%' }}><a href="#">Хүний нөөц ил тод »</a>
              <ul>
                <li style={{ width: '100%' }}><Link to="/pages/46">Ажлын байрны зар</Link></li>
                <li style={{ width: '100%' }}><Link to="/pages/45">Хүний нөөцийн стратеги</Link></li>
                {/* <li style={{ width: '100%' }}><Link to="/pages/49">Ил тод байдал</Link></li> */}
                <li style={{ width: '100%' }}><Link to="/pages/50">Авилгын эсрэг</Link></li>
              </ul>
            </li>
            <li style={{ width: '100%' }}><a href="#">Санхүүгийн ил тод байдал»</a>
              <ul>
                {/* <li style={{ width: '100%' }}><Link to="/pages/51">Санхүүгийн тайлан</Link></li> */}
                <li style={{ width: '100%' }}><Link to="/pages/52">Шилэн данс</Link></li>
                {/* <li style={{ width: '100%' }}><Link to="/pages/53">Аудитын тайлан дүгнэлт</Link></li> */}
                {/* <li style={{ width: '100%' }}><Link to="/pages/54">Хөрөнгө оруулалт</Link></li> */}
              </ul>
            </li>
            {/* <Link to="/pages/55">Тендер</Link> */}
            <Link to="/pages/56">Ёс зүйн хороо</Link>
            {/* <Link to="/pages/57">Үйлдвэрчний эвлэлийн хороо</Link> */}
          </ul>
        </li>
      </ul>
    </div>
  )
}
