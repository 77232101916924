import React,
{ useEffect, useState }
  from "react";
import HeaderMenu from '../header/index';
import FooterMenu from '../footer/index';
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import ApiService from "../../Services/NoteService";


export default function Index({ children }) {

  const { Content, Footer } = Layout;
  const [roles, setRoles] = useState(0);
  const navigate = useNavigate();
  // token Шалгах
  const [user, setUser] = useState([]);
  const [wait, setWait] = useState(true);

  // var token = localStorage.getItem("accessToken")
  //   ? localStorage.getItem("accessToken")
  //   : "";

  const datasget = async () => {
    try {
      const res = await ApiService.check();
      if (res.data.status) {
        setUser(res.data.token.user);
        // setRoles(res.data.token.user.UserID);
      } else {
        localStorage.clear();
        navigate("/login");
      }
      setWait(false);
    } catch (err) {
      localStorage.clear();
      navigate("/login");
    }
  }

  // useEffect(() => {
  //   if (token) {
  //     datasget()
  //   } else {
  //     localStorage.clear();
  //     navigate("/login");
  //   }
  //   // Хэрэглэгчдийн эрхийг авж байна
  //   if (!wait && !user) {
  //     localStorage.clear();
  //     navigate("/login");
  //   }
  // }, []);

  return (
    <>
      <Layout>
          <HeaderMenu />
        <Content>
          {children}
        </Content>
          <FooterMenu />
      </Layout>
    </>
  )
}
