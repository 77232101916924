import React, { useState, useEffect } from 'react'
import Menu from '../menu/index'
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const history = useNavigate();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 220) {
      setShowScroll(true);
    } else if (window.pageYOffset < 220) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
  }, []);
  return (
    <>
      {/* <div className='p-2 bg-white' style={{ borderBottom: "1px solid #e0e0e0" }}>
        <div className='container'>
          <div className=''>
            <ul className='xl:flex'>
              <li className=''>Call center: 11-341003</li>
              <li className='ml-10 cursor-pointer' onClick={() => { history("") }}>Лого: Татах</li>
              <a href='mailto:info@huemt.ub.gov.mn' className='no-underline text-slate-900'><li className='ml-10 cursor-pointer'> И-мэйл: Илгээх</li></a>
              <a href='https://mail.gov.mn/' className='no-underline text-slate-900'><li className='ml-10 cursor-pointer'>WebEmail: Шалгах</li></a>
              <a href='/login' className='no-underline text-slate-900'>
                <li className='ml-10 cursor-pointer'>Нэвтрэх</li>
              </a>
            </ul>
          </div>
        </div>
      </div> */}
      {/* <div className="bg-white">
        <div className='container'>
          <div className="pt-6 pb-6 max-sm:pl-10">
            <img style={{ height: 150 }} src="/assets/images/main_logo.jpg" />
          </div>
        </div>
      </div> */}
      <div className='bg uppercase' style={{ height: 49 }}>
        <div className='container'>
          <Menu />
        </div>
      </div>
      <button className="scrollTop text-white font-bold py-2 px-4 rounded"
        onClick={scrollTop}
        style={{
          right: 10,
          bottom: 20,
          height: 40,
          color: "white",
          position: "fixed",
          border: "1px solid",
          background: "#348ade",
          display: showScroll ? "flex" : "none",
        }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
        </svg>
      </button>
    </>
  )
}

export default Index