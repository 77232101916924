import React from 'react'

export default function Index() {
  return (
    <>
      <div className='bg-blue-400'>
        <div className='container'>
          <div className="max-sm:p-4">
            <div className="text-zinc-100">
              {/* <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">Trusted by the world’s most innovative teams</h2> */}
              <div className="mt-10 mb-10 grid max-w-lg xl:grid-cols-4 items-center xl:gap-x-8 xl:gap-y-10 sm:max-w-full sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div className="col-span-2 w-full object-contain lg:col-span-1">
                  <div className="textwidget custom-html-widget">
                    {/* <p>
                      <a href="http://www.huemt.ub.gov.mn">
                        <img src="http://www.huemn.ub.gov.mn/wp-content/uploads/2021/12/Logo100-1.png" alt="" />
                      </a>
                    </p> */}
                    <div style={{ fontSize: 18 }} className='font-bold'>
                      Байгууллагын хаяг:</div>
                    <div>Увс аймгийн нэгдсэн эмнэлэг</div>
                    <br />
                    <div style={{ fontSize: 18 }} className='font-bold'>
                      Холбоо барих:
                    </div>
                    <div>Цахим утас: <strong>70453322</strong></div>
                    <div>Яаралтай тусламж: <strong>7045103 </strong></div>
                  </div>
                </div>
                <div className="col-span-2 w-full object-contain lg:col-span-1">
                  {/* <h2 className="widgettitle">СУДАЛГАА</h2> */}
                  <div id="polls-2" className="wp-polls">
                  </div>
                </div>
                {/* <div className="col-span-2 w-full object-contain lg:col-span-1">
                  <p style={{ fontSize: 18 }} className="widgettitle font-bold">ТУСЛАХ ЦЭС</p>
                  <nav className="menu-footer-menu-container" aria-label="ТУСЛАХ ЦЭС">
                    <ul id="menu-footer-menu" className="menu">
                      <li id="menu-item-17094" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17094"><a href="https://www.huemn.ub.gov.mn/?page_id=10587">› Үйлчлүүлэгчийн эрх, үүрэг</a></li>
                      <li id="menu-item-17097" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17097"><a href="https://www.huemn.ub.gov.mn/?page_id=2344">› Эмнэлгийн хуудас /ЛИСТ/ олгох</a></li>
                      <li id="menu-item-17098" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17098"><a href="https://www.huemn.ub.gov.mn/?page_id=10575">› Эмнэлэг хөдөлмөр магадлах комисс</a></li>
                      <li id="menu-item-17101" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17101"><a href="https://www.huemn.ub.gov.mn/?page_id=10601">› Мэдээлэл технологийн үйлчилгээ</a></li>
                      <li id="menu-item-17100" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17100"><a href="https://www.huemn.ub.gov.mn/?page_id=10651">› Ажилчдын утасны жагсаалт</a></li>
                      <li id="menu-item-17138" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17138"><a href="https://www.huemn.ub.gov.mn/?page_id=8337">› Өрхийн эрүүл мэндийн төвүүд</a></li>
                      <li id="menu-item-17108" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17108"><a href="https://www.huemn.ub.gov.mn/?page_id=17104">› Иргэдтэй уулзах хуваарь</a></li>
                      <li id="menu-item-17112" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17112"><a href="https://www.huemn.ub.gov.mn/?page_id=4910">› Хаяг, байршил</a></li>
                      <li id="menu-item-17099" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17099"><a href="https://www.huemn.ub.gov.mn/?page_id=10578">› Санал, хүсэлт</a></li>
                    </ul></nav>
                </div> */}
                <div className="col-span-2 w-full object-contain lg:col-span-1">
                  <p style={{ fontSize: 18 }} className="widgettitle font-bold">Cайтын хандалт</p>
                  <nav className="menu-footer-menu-container" aria-label="ТУСЛАХ ЦЭС">
                    <ul id="menu-footer-menu" className="menu">Нийт хандалт: 356
                    </ul></nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg'>
        <div className="pt-3 pb-3 text-white">
          <div className="container">
            © Copyright 2023 - Бүх эрх хуулиар хамгаалагдсан.
          </div>
        </div>
      </div>
    </>
  )
}
