import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from './components/layout/index';
import "./styles/header_css/home.module.scss";

// //Pages
const Home = lazy(() => import('./pages/home/index'));
const Posts = lazy(() => import('./pages/posts/Index'));
const Pages = lazy(() => import('./pages/pages/Index'));
const Admin = lazy(() => import('./pages/admin/Index'));
const News = lazy(() => import('./pages/news/Index'));
const Login = lazy(() => import('./pages/admin/Login'));
const Articles = lazy(() => import('./pages/admin/articles/Index'));
const AdminPages = lazy(() => import('./pages/admin/pages/Index'));
const ArticlesAdd = lazy(() => import('./pages/admin/articles/new'));
const PagesAdd = lazy(() => import('./pages/admin/pages/new'));
const ArticlesEdit = lazy(() => import('./pages/admin/articles/edit/Index'));
const PagesEdit = lazy(() => import('./pages/admin/pages/edit/Index'));
// const Articles_add = lazy(() => import('./pages/admin/articles/new'));
// const Articles_edit = lazy(() => import('./pages/admin/articles/edit/Index'));
// const Login = lazy(() => import('./Pages/Login/Index'));
// const Person = lazy(() => import('./Pages/Person/Index'));
// const NotFound = lazy(() => import('./Pages/NotFound/Index'));

// //Components
const Loading = lazy(() => import('./components/loading/index'));

const Sus = ({ children }) => {
  return (
    // <Layout>
    <Suspense fallback={<Loading />}>
      {children}
    </Suspense>
    // </Layout>
  )
}

function App() {
  return (
    <Router>
      <Routes>
        
          {/* <Route path="/login" element={<Login />} exact={true}/> */}
          <Route path="/" element={<Layout><Sus><Home /></Sus></Layout>} exact={true} />
          <Route path="/login" element={<><Login /></>} exact={true} />
          <Route path="/admin" element={<><Sus><Admin /></Sus></>} exact={true} />
          <Route path="/admin/article" element={<><Sus><Articles /></Sus></>} exact={true} />
          <Route path="/admin/pages" element={<><Sus><AdminPages /></Sus></>} exact={true} />
          <Route path="/admin/article/new" element={<><Sus><ArticlesAdd /></Sus></>} exact={true} />
          <Route path="/admin/pages/new" element={<><Sus><PagesAdd /></Sus></>} exact={true} />
          <Route path="/admin/article/edit/:id" element={<><Sus><ArticlesEdit /></Sus></>} exact={true} />
          <Route path="/admin/pages/edit/:id" element={<><Sus><PagesEdit /></Sus></>} exact={true} />
          <Route path="/posts/:id" element={<Layout><Sus><Posts /></Sus></Layout>} exact={true} />
          <Route path="/pages/:id" element={<Layout><Sus><Pages /></Sus></Layout>} exact={true} />
          <Route path="/news/:page" element={<Layout><Sus><News /></Sus></Layout>} exact={true} />
          {/* <Route path="/Person" element={<Sus><Person /></Sus>} exact={true} /> */}
          {/* <Route path="/*" element={<NotFound />} exact={true} /> */}
        
      </Routes>
    </Router>
  );
}

export default App;