import http from "./http-common";

const create = (params, data) => {
  var token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : "";
  return http.post(`/api/${params}`, data, {
    headers: {
      'x-access-token': token
    },
  });
};

const get = (params) => {
  var token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : "";
  return http.get(`/api/${params}`, {
    headers: {
      'x-access-token': token
    },
  });
};

const update = (params, data) => {
  var token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : "";
  return http.put(`/api/${params}`, data, {
    headers: {
      'x-access-token': token
    },
  });
};

const check = () => {

  var token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : "";

  return http.get("/api/check", {
    headers: {
      'x-access-token': token
    },
  });
};

const deleteData = (id) => {

  var token = localStorage.getItem("accessToken")
  ? localStorage.getItem("accessToken")
  : "";

  return http.delete("/api/note/delete/"+id, {
    headers: {
      'x-access-token': token
    },
  });
};

export default {
  get,
  create,
  update,
  deleteData,
  check
};
